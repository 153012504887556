/* Wallet CSS */
.titleWallet {
    /* font-family: Arial, Helvetica, sans-serif; */
    display: flex;
    align-items: center;
    justify-content: end;
}

.modal-login-btn {
    background: linear-gradient(-30deg, #571b94 50%, #461772 50%);
    padding: 15px 20px;
    border: 0;
    /* margin: 0 auto !important; */
    display: inline-block;
    -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
    overflow: hidden;
    color: #e6d4f7;
    /* font-size: 20px; */
    /* letter-spacing: 2.5px; */
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    /* -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5); */
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
        box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
        color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;

    -webkit-animation: glowing-kyc 1500ms infinite;
    -moz-animation: glowing-kyc 1500ms infinite;
    -o-animation: glowing-kyc 1500ms infinite;
    animation: glowing-kyc 1500ms infinite;
}

@keyframes glowing-kyc {
    0% {
        background-color: #9b31ff;
        -webkit-box-shadow: 0 0 3px #9b31ff;
    }

    50% {
        background-color: #9b31ff;
        -webkit-box-shadow: 0 0 20px #9b31ff;
    }

    100% {
        background-color: #9b31ff;
        -webkit-box-shadow: 0 0 3px #9b31ff;
    }
}

.modal-login-btn::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #9985ad;
    opacity: 0;
    -webkit-transition: .2s opacity ease-in-out;
    transition: .2s opacity ease-in-out;
}

.modal-login-btn:hover::before {
    opacity: 0.2;
}

.modal-login-btn span {
    position: absolute;
}

.modal-login-btn span:nth-child(1) {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 2px;
    background: -webkit-gradient(linear, right top, left top, from(rgba(26, 8, 43, 0)), to(#b366ff));
    background: linear-gradient(to left, rgba(26, 8, 43, 0), #b366ff);
    -webkit-animation: 2s animateTop linear infinite;
    animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
    0% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }

    100% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

.modal-login-btn span:nth-child(2) {
    top: 0px;
    right: 0px;
    height: 100%;
    width: 2px;
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(26, 8, 43, 0)), to(#b366ff));
    background: linear-gradient(to top, rgba(26, 8, 43, 0), #b366ff);
    -webkit-animation: 2s animateRight linear -1s infinite;
    animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
    0% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }

    100% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
}

.modal-login-btn span:nth-child(3) {
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 2px;
    background: -webkit-gradient(linear, left top, right top, from(rgba(26, 8, 43, 0)), to(#b366ff));
    background: linear-gradient(to right, rgba(26, 8, 43, 0), #b366ff);
    -webkit-animation: 2s animateBottom linear infinite;
    animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
    0% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }

    100% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
}

.modal-login-btn span:nth-child(4) {
    top: 0px;
    left: -30px;
    height: 100%;
    width: 2px;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(26, 8, 43, 0)), to(#b366ff));
    background: linear-gradient(to bottom, rgba(26, 8, 43, 0), #b366ff);
    -webkit-animation: 2s animateLeft linear -1s infinite;
    animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
    0% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }

    100% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
}


.alertWalletDiv {
    margin-top: -2.5rem;
}

.walletIcon {
    width: 40px;
}

.wallet-texts {
    /* font-family: Arial, Helvetica, sans-serif !important; */
    font-size: 16px !important;
}

.dialogDiv-coinbase {
    background-color: #060c13;
    color: white;
}

.coinbase-dialog {
    padding-left: 100px !important;
    padding-right: 100px !important;
}

.click-here {
    cursor: pointer;
}

.contents {
    /* font-family: Arial, Helvetica, sans-serif; */
    font-weight: normal;
    color: #a2a2a2;
    text-align: center;
    margin-bottom: 0rem;

}

.contentDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.walletDiv span {
    /* font-family: Arial, Helvetica, sans-serif; */
    margin-left: 2rem;
    font-weight: bold;
}

.walletDiv {
    cursor: pointer;
    border: none;
    text-align: center;
    padding-top: 26px;
    color: #a2a2a2;
    /* font-family: Montserrat; */
    font-weight: bold;
    background-color: #060c13 !important;
    width: 100% !important;
}

.walletModal_icon {
    margin-left: 0% !important;
}

.closeBtn {
    display: flex;
    align-items: center;
    justify-content: end;
}

.imgWallet {
    width: 50px !important;
    margin: 0 auto;
}

.dialogWallet {
    background-color: #060c13;
    color: white;
}

.dialogDiv {
    border-radius: 20px;
}

.walletName {
    font-size: 1rem;
    color: #a2a2a2;

}

.font-style {
    font-family: Arial, Helvetica, sans-serif;
}

.walletFont {
    margin-right: 6px;
}

.contents1 {
    color: #a2a2a2;
    text-decoration: none;

}

.contents1:hover {
    color: #fff;

}

.walletdiv {
    display: flex;
    align-items: center;
    justify-content: end;
}

.connectMenu {
    border: none;
    background-color: rgb(237, 41, 41);
    color: white;
    padding-top: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.meal_details_type_title {
    margin-left: 23.5px !important;
    margin-bottom: -28px !important;
    font-size: 22px !important;
}

.paymentOptionsChoose {
    margin-top: 0px !important;
    margin-bottom: -25px !important;
}

.howItWorksFs {
    font-size: 1rem !important;
    font-weight: 400 !important;
}


@media only screen and (max-width: 650px) {

    .modal-login-btn {
        font-size: small;
        padding: 10px 20px;
    }
}