.profileEnrolBtn {
  background: #ff5421;
  border-color: #ff5421;
  color: #fff;
  width: 100%;
  padding: 6px 20px;
}
.walletPartSecond {
  background: #100c44dc;
}
.inputgap {
  padding: 10px 0;
}
.walletPartFirst {
  background: #8f5f5f;
}
.learnTop {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.learnBnt {
  background: #100c44dc;
  border-color: none;
  color: #fff;
  width: 100%;
  padding: 6px 20px;
}

.profileEnrolBtnMint {
  background: #ff5421;
  border-color: #ff5421;
  color: #fff;
  width: auto;
  padding: 6px 20px;
  border-radius: 6px;
}

.backBtn {
  background: #d32f2f;
  border-color: #d32f2f;
  color: #fff;
  width: auto;
  padding: 6px 36px;
  border-radius: 6px;
}

/* .mordetailsBtn {
  font-size: 10px !important;
  border: none;
  border-radius: 6px;
  padding: 6px 8px;
  background-color: #ff5421;
  color: white;
} */

.profile-inputs::placeholder {
  font-size: 12.5px !important;
}

.enrolLength {
  background-color: #ff5421;
  color: white;
  padding: 0px 5px;
  border-radius: 6px;
  position: absolute;
  font-size: 12px;
  display: inline-block;
}

.course-content-type-selection-container {
  position: relative;
}
.affliatteText{
  text-align: start;
}
.course-content-items {
  position: absolute;
  top: 45px;
  width: 115px;
  background-color: white;
}

.course-content-items li {
  list-style: none;
  padding-inline: 8px;
  padding-block: 2px;
  transition: 0.3s all;
  cursor: pointer;
}
.course-content-items li:hover {
  background-color: #ebeaea;
}

.btns{
  color: #fff;
  /* background: linear-gradient(135deg, #852d91, #312a6c); */
  background: linear-gradient(135deg, #60bde5, #010002);
  /* font-family: 'Comfortaa', cursive; */
  font-size: 20px;
  font-weight: 600;
  /* text-transform: lowercase; */
  padding: 10px 30px;
  width: 100%;
  border: none;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
}
.btns:hover{
  color: #fff;
  border: none;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  animation: rotate 0.7s ease-in-out both;
}
.btns:before,
.btns:after{
  content: '';
  background: #fff;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  opacity: 0;
  transform: translate(100%, -25%) translate3d(0, 0, 0);
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
  transition: all 0.15s cubic-bezier(0.02, 0.01, 0.47, 1);
}
.btns:hover:before,
.btns:hover:after{
  opacity: 0.15;
}
.btns:hover:before{ transform: translate3d(50%, 0, 0) scale(0.9); }
.btns:hover:after{ transform: translate(50%, 0) scale(1.1); }
@keyframes rotate{
  0%{ transform: rotate(0deg); }
  25%{ transform: rotate(3deg); }
  50%{ transform: rotate(-3deg); }
  75%{ transform: rotate(1deg); }
  100%{ transform: rotate(0deg); }
}
@media only screen and (max-width: 767px){
  .btns{ margin-bottom: 20px; }
}


@media only screen and (max-width: 600px) {
  .imageDivNft {
    margin: auto;
  }
  .social-div{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .affliatteText{
    text-align: center;
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .profile-btns {
    width: 100% !important;
    font-size: 13px;
  }
}
