.headerText {
  font-size: 24px;
  font-weight: 600;
}

.handleReduce {
  position: relative;
  left: -10px;
}

.product-th {
  font-weight: 600 !important;
}

.layer_bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  opacity: 0.7;
  background: rgba(0, 0, 0, 0.932);
  display: grid;
}

.alt_text {
  border: 1px solid#03a9f4;
  padding: 20px;
  color: #03a9f4;
  background: #e2f6ff;
  font-size: 14px;
  border-radius: 1px;
  padding: 11px;
}

.serviceLink {
  text-decoration: none !important;
  color: #7fb1eb !important;
}

.tableRow2 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.serviceLink:hover {
  text-decoration: none;
  color: #185eaf !important;
}

.serviceLinkSite {
  text-decoration: none !important;
  color: #000000 !important;
}

.serviceLinkSite:hover {
  text-decoration: none;
  color: #1f1f1f !important;
}

.product_list {
  width: 100%;
}

.product_list th {
  text-align: left !important;
}

.doneIcon {
  color: red !important;
  font-weight: 900 !important;
  font-size: 2.1rem !important;
}

.doneIconMain {
  color: rgb(139, 90, 0) !important;
  font-weight: 900 !important;
  font-size: 2.1rem !important;
}

.doneIconPublic {
  color: green !important;
  font-weight: 900 !important;
  font-size: 2.1rem !important;
}

.w_lg_50 {
}

@media only screen and (max-width: 769px) {
  .product_banner {
    height: 25vh !important;
  }
}

@media only screen and (max-width: 600px) {
  .w_lg_50 {
    width: 50%;
  }
  .headerText {
    font-size: 16px;
    font-weight: 600;
  }
}

.product_list th,
.product_list td {
  border-bottom: 1px solid #ececec;
  padding: 8px 9px 8px 0px;
  text-align: left;
}

@media (max-width: 700px) {
  .product_list tr {
    border-bottom: 1px solid #ececec;
    padding: 8px 9px 8px 0px;
  }

  .handleReduce {
    position: relative;
    left: 0px;
  }

  .spacing {
    padding-left: 10px !important;
  }

  .breaker {
    padding-left: 8px;
  }

  .product_list th:nth-child(4) {
    text-align: center !important;
  }

  .handleTop {
    height: 125px !important;
  }

  .handleTopOther {
    height: 100px !important;
  }

  .handleAlignSpecial {
    position: relative;
    top: 15px;
    padding-left: 7px !important;
  }

  .handleTopSpecial {
    height: 130px !important;
  }

  .handleformini {
    padding-left: 8px !important;
  }

  .product_list th {
    border: none;
    display: table-row;
    /* width: 100%; */
    text-align: left !important;
  }

  .product_list td {
    border: none;
    display: table-row;
  }

  .specialHandleTh {
    display: table-cell !important;
  }

  .product_list th:nth-child(4),
  .product_list th:nth-child(5),
  .product_list td:nth-child(6),
  .product_list td:nth-child(4),
  .product_list td:nth-child(5),
  .product_list td:nth-child(6) {
    display: table-cell;
  }
}

@media (max-width: 400px) {
  .product_list tr {
    border-bottom: 1px solid #ececec;
    padding: 8px 9px 8px 0px;
  }

  .product_list {
    font-size: small !important;
  }

  .breaker {
    padding-left: 8px;
  }

  .handleTopSpecial {
    height: 140px !important;
  }

  .handleformini {
    padding-left: 8px !important;
  }

  .handleTop {
    height: 125px !important;
  }

  .handleTopOther {
    height: 110px !important;
  }

  .handleTopSpecial {
    height: 120px;
  }

  .handleAlignSpecial {
    position: relative;
    top: 25px;
    /* border: 1px solid black; */
    padding-left: 7px !important;
  }

  .product_list th:nth-child(4) {
    text-align: center !important;
  }

  .product_list th {
    border: none;
    display: table-row;
    text-align: left !important;
  }

  .product_list td {
    border: none;
    display: table-row;
  }

  .product_list th:nth-child(4),
  .product_list th:nth-child(5),
  .product_list td:nth-child(6),
  .product_list td:nth-child(4),
  .product_list td:nth-child(5),
  .product_list td:nth-child(6) {
    display: table-cell;
  }
}

@media screen and (max-width: 510px) {
  .product-th {
    position: relative;
    top: -15px;
  }

  .handleTopSpecial {
    height: 140px !important;
  }

  .handleformini {
    padding-left: 8px !important;
  }
}

/* BRG */

.product_banner {
  background-image: url("/src/assets/img/products.png") !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 45vh;
  position: relative;
  background-color: rgba(0, 0, 0, 0.7) !important;
  background-blend-mode: overlay !important;

  /* background-position: center !important;
    background-image: url(/public/image/products.png) !important;
    background-size: cover !important;
    height: 45vh;
    position: relative;
    background-color: rgba(0, 0, 0, .7) !important;
    background-blend-mode: overlay !important; */
}

.product-pdf-cards {
  padding: 50px;
  width: 300px;
  background: #18223a;
  box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

.handleReduce {
  position: relative;
  left: -10px;
}

.background_product {
  height: auto;
  background-color: #171f32 !important;
}

.product-heading {
  text-transform: uppercase;
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 600 !important;
  font-size: 35px !important;
  line-height: 45px !important;
  text-align: center;

  background: linear-gradient(92.2deg, #ce1aba 0.89%, #4a9cd7 76.14%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.product-click-here {
  padding: 5px 10px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  text-transform: uppercase;
  outline: none !important;
  border: none;
  text-decoration: none;
  color: black;
}

.product-card-text {
  font-size: 17px !important;
  font-weight: 500 !important;
}

.table-row-style {
  /* display: block; */
  box-sizing: border-box;
  border: 2px solid #ffffff;
  /* width: 200% !important; */
  border-radius: 15px !important;
}

/* .background_product {
    background-position: center !important;
    background-image: url("../../../ImagePro/productPageTwo.jpg") !important;
    background-size: cover !important;
    height: auto;
    background-color: rgba(0, 0, 0, 0.616) !important;
    background-blend-mode: overlay !important;
  } */

.product-th {
  font-weight: 600 !important;
}

.layer_bg_product {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  opacity: 0.7;
  /* background: rgba(0, 0, 0, 0.932); */
  display: grid;
}

.dsl-products-banner {
  width: 556px;
  height: auto;
}

.alt_text {
  border: 1px solid#03a9f4;
  padding: 20px;
  color: #03a9f4;
  background: #e2f6ff;
  font-size: 14px;
  border-radius: 1px;
  padding: 11px;
}

.product-name {
  margin-top: 10px !important;
}

.serviceLink {
  text-decoration: none !important;
  color: #7fb1eb !important;
}

.tableRow2 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.serviceLink:hover {
  text-decoration: none;
  color: #185eaf !important;
}

.serviceLinkSite {
  text-decoration: none !important;
  color: #000000 !important;
}

.serviceLinkSite:hover {
  text-decoration: none;
  color: #1f1f1f !important;
}

.product_list {
  width: 100%;
}

.product_list th {
  text-align: left !important;
}

.doneIcon {
  color: red !important;
  font-weight: 900 !important;
  font-size: 2.1rem !important;
}

.doneIconMain {
  color: rgb(139, 90, 0) !important;
  font-weight: 900 !important;
  font-size: 2.1rem !important;
}

.doneIconPublic {
  color: green !important;
  font-weight: 900 !important;
  font-size: 2.1rem !important;
}
.single-net {
  margin-bottom: 0.5rem !important;
}
@media only screen and (max-width: 769px) {
  .product_banner {
    height: 25vh !important;
  }
}

@media only screen and (max-width: 600px) {
  .product-heading {
    font-size: 22px !important;
    line-height: 35px !important;
  }
  .product-sub-heading {
    font-size: 13.5px !important;
  }

  .single-product {
    padding-left: 12px !important;
  }
  .product-name {
    font-size: 19.5px !important;
  }
  .product-details {
    font-size: 14px !important;
  }

  .product-tick {
    /* position: unset !important; */
    width: 20px !important;
    height: 20px !important;
  }
  .product-image-div {
    padding-right: 16px !important;
  }
  .single-net {
    font-size: 15px !important;
    padding-right: 10px;
  }
  .all-nets-div {
    /* padding-right: 10px; */
    position: relative;
    right: 12px;
  }

  .testnet-tick {
    left: -0.1rem !important;
  }
  .mainnet-tick {
    left: -1rem !important;
  }
  .publicnet-tick {
    left: -1.5rem !important;
  }

  .w_lg_50 {
    width: 50%;
  }

  .dsl-products-banner {
    width: 100%;
    height: auto;
  }
}

.product_list th,
.product_list td {
  border-bottom: 1px solid #ececec;
  padding: 8px 9px 8px 0px;
  text-align: left;
}

@media (max-width: 700px) {
  .product_list tr {
    border-bottom: 1px solid #ececec;
    padding: 8px 9px 8px 0px;
  }

  .handleReduce {
    position: relative;
    left: 0px;
  }

  .spacing {
    padding-left: 10px !important;
  }

  .breaker {
    padding-left: 8px;
  }

  .product_list th:nth-child(4) {
    text-align: center !important;
  }

  .handleTop {
    height: 125px !important;
  }

  .handleTopOther {
    height: 100px !important;
  }

  .handleAlignSpecial {
    position: relative;
    top: 15px;
    padding-left: 7px !important;
  }

  .handleTopSpecial {
    height: 130px !important;
  }

  .handleformini {
    padding-left: 8px !important;
  }

  .product_list th {
    border: none;
    display: table-row;
    /* width: 100%; */
    text-align: left !important;
  }

  .product_list td {
    border: none;
    display: table-row;
  }

  .specialHandleTh {
    display: table-cell !important;
  }

  .product_list th:nth-child(4),
  .product_list th:nth-child(5),
  .product_list td:nth-child(6),
  .product_list td:nth-child(4),
  .product_list td:nth-child(5),
  .product_list td:nth-child(6) {
    display: table-cell;
  }
}

@media (max-width: 400px) {
  .product_list tr {
    border-bottom: 1px solid #ececec;
    padding: 8px 9px 8px 0px;
  }

  .product_list {
    font-size: small !important;
  }

  .breaker {
    padding-left: 8px;
  }

  .handleTopSpecial {
    height: 140px !important;
  }

  .handleformini {
    padding-left: 8px !important;
  }

  .handleTop {
    height: 125px !important;
  }

  .handleTopOther {
    height: 110px !important;
  }

  .handleTopSpecial {
    height: 120px;
  }

  .handleAlignSpecial {
    position: relative;
    top: 25px;
    /* border: 1px solid black; */
    padding-left: 7px !important;
  }

  .product_list th:nth-child(4) {
    text-align: center !important;
  }

  .product_list th {
    border: none;
    display: table-row;
    text-align: left !important;
  }

  .product_list td {
    border: none;
    display: table-row;
  }

  .product_list th:nth-child(4),
  .product_list th:nth-child(5),
  .product_list td:nth-child(6),
  .product_list td:nth-child(4),
  .product_list td:nth-child(5),
  .product_list td:nth-child(6) {
    display: table-cell;
  }
}

@media screen and (max-width: 510px) {
  .br-style {
    display: none;
  }
  .product-th {
    position: relative;
    top: -15px;
  }

  .handleTopSpecial {
    height: 140px !important;
  }

  .handleformini {
    padding-left: 8px !important;
  }
}
@media screen and (max-width: 375px) {
  .all-nets-div {
    position: relative;
    right: 15px !important;
  }
  .single-net {
    font-size: 12px !important;
  }
  .product-tick {
    width: 17px !important;
    height: 17px !important;
  }
}
@media screen and (max-width: 992px) {
  .product-card-container {
    flex-direction: column;
  }
  .product-pdf-cards {
    margin-bottom: 10px;
    width: 100%;
  }
}
@media screen and (min-width: 412px) and (max-width: 599px) {
  .all-nets-div {
    padding-right: 0 !important;
  }
}
