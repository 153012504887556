.detailsVideo {
    /* width: 500px;
    height: 450px; */
}

.overview-pdf {
    height: 31.5rem !important;
}

.videoDiv {
    display: flex;
    align-items: start;
    justify-content: start;
}

.over-view-button-container button {
    color: #ffffff;
    background: #ff5421;
    border: none;
    position: relative;
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    padding: 12px 40px 10px;
    margin-bottom: 15px;
}
.scholarship-modal .modal.show .modal-dialog{
    max-width: 800px;
}
@media only screen and (max-width: 500px) {
    .over-view-button-container button {
        margin-left: 1.2rem;
    }
}