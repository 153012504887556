.closeD {
    text-align: right;
}


.iconCloseR {
    position: absolute;
    top: 2px;
    right: 5px;
    width: 40px;
    height: 40px;
    background: none !important;
    border: none !important;
    font-size: 25px !important;

}


.banner-button2 {
    /* background-color: rgb(123, 123, 148) !important; */
    margin: 10px;
    padding: 5px 20px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    font-weight: 500;
    border: none;
}

.font14 {
    font-size: 14px;
}

.otp-bg-color {
    background-color: #ff5421 !important;
}