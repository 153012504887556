.studentBtnAdd{
    background-color: rgb(52, 167, 52);
    border: none;
    border-radius: 7px;
    margin-right: 2px;

}
.studentView{
    background-color: rgb(58, 52, 167);
    border: none;
    border-radius: 7px;
    margin-right: 2px;
    color: white;
}
.studentBtnDlt{
    background-color: rgb(195, 62, 62);
    border: none;
    border-radius: 7px;
}
.studentBtnEdit{
    background-color: #027d2d;
    margin-right: 2px;
    border: none;
    border-radius: 7px;
}