.customB{
  border: 2px solid #333;
  border-radius: 50%;
  font-size: 20px;
  padding: 10px 5px;
  text-align: center;
  font-weight: 600;
}
.custom5{
  border: 2px solid #333;
  border-radius: 50%;
  font-size: 20px;
  padding: 10px 14px;
  text-align: center;
  font-weight: 600;
}
.customX{
  border: 2px solid #333;
  border-radius: 50%;
  font-size: 20px;
  padding: 10px 20px;
  text-align: center;
  font-weight: 600;
}