.companion-ai-main {
  margin-top: 30px;
}

.custom-select {
  position: relative;
}

.custom-select select {
  width: 100%;
  padding: 10px;
  appearance: none; /* Remove default arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
}

.custom-select::before {
  content: '▼';
  position: absolute;
  top: 50%;
  right: 15px; /* Adjust this value to move the arrow */
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 5;
}

.credits-button {
  border: none;
  background-color: #d9d9d9;
  font-weight: 500;
  padding-inline: 42px;
  padding-block: 11px;
  border-radius: 3px;
}
.unity {
  height: 386px;
  border-radius: 10px;
}
.h-70{
  height: 70px!important;
}
.loading-overlay {
  position: absolute;
  width: max-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.buy-credits-button {
  padding-inline: 12px;
  padding-block: 11px;
  margin-inline: 10px;
  border: none;
  color: white;
  background: linear-gradient(180deg, #3080ac 24.81%, #08243b 118.18%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.money-exchange-button img {
  width: 22px;
}

.comp-clear-button {
  padding-inline: 12px;
  padding-block: 11px;
  /* margin-inline: 10px; */
  border: none;
  color: white;
  background: rgb(207, 18, 18);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.money-exchange-button {
  border: none;
  padding-block: 9px;
  padding-inline: 9px;
  border-radius: 3px;
}

.chatai-icon-styles:hover {
  color: lightblue;
  transition: 0.5s;
}

.selector-container {
  margin-top: 30px;
}

.selector {
  height: 44px;
  border-radius: 4px;
  background-color: #d9d9d9;
  color: #826e6e;
  width: 600px;
  padding-inline: 10px;
}
.CreditDiv{
  display: flex;
  align-items: start;
}
.selector-second {
  height: 44px;
  border-radius: 4px;
  background-color: #d9d9d9;
  color: #826e6e;
  width: 100%;
  padding-inline: 10px;
}
.mute-icon {
  width: 25px;
  margin-left: 10px;
  margin-bottom: 6px;
}

.first-field-container {
  margin-top: 9px;
}

.ant-form-item-required {
  color: white !important;
}

.companion-res-area {
  width: 100%;
  background-color: #d9d9d9;
  border-radius: 4px;
  height: 150px !important;

  padding: 20px;
  overflow: auto;
}

.second-field-container {
  margin-top: 12px;
}

.second-field-container p {
  color: #c5b0b0;
  font-size: 18px;
  margin-bottom: 10px;
}

.second-text-area {
  width: 100%;
  background-color: #d9d9d9;
  border-radius: 4px;
  height: 70px;
  /* overflow-y: auto; */
  padding: 8px;
}

.next-button {
  border: none;
  padding-inline: 20px;
  /* padding-block: 8px; */
  padding-top: 8px;
  padding-bottom: 10px;
  border: none;
  border-radius: 3px;
  /* font-weight: 400; */
  font-size: 18px;
  font-weight: 500;

  display: block;
  margin-inline: auto;
  color: white;
  margin-top: 20px;
  background: linear-gradient(180deg, #3080ac 24.81%, #08243b 118.18%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.back-button {
  border: none;
  padding-inline: 20px;
  /* padding-block: 8px; */
  padding-top: 8px;
  padding-bottom: 10px;
  border: none;
  border-radius: 3px;
  /* font-weight: 400; */
  font-size: 18px;
  font-weight: 500;

  display: flex;
  margin-inline: auto;
  color: white;
  margin-top: -42px;

  margin-left: 430px;
  background: linear-gradient(180deg, #3080ac 24.81%, #08243b 118.18%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.submit-button {
  border: none;
  padding-inline: 20px;
  /* padding-block: 8px; */
  padding-top: 8px;
  margin-top: 30px;
  padding-bottom: 10px;
  margin-right: -150px;
  border: none;
  border-radius: 3px;
  /* font-weight: 400; */
  font-size: 18px;
  font-weight: 500;

  display: flex;
  flex-direction: row;
  margin-inline: auto;
  color: white;
  background: linear-gradient(180deg, #3080ac 24.81%, #08243b 118.18%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  /* border-right: .15em solid orange; */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  /* letter-spacing: .15em;  */
  animation: 
    typing 3.5s steps(30, end),
    blink-caret .8s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange }
}

@media screen and (max-width: 768px) {
  .credits-button {
    padding-inline: 15px !important;
    padding-block: 11px;
    font-size: 10px;
  }
  .comp-clear-button {
    padding-inline: 15px !important;
    padding-block: 11px;
    font-size: 11px;
  }

  .buy-credits-button {
    padding-inline: 8px;
    padding-block: 11px;
    margin-inline: 10px;
    font-size: 10px;
  }

  .money-exchange-button {
    padding-block: 6px !important;
    padding-inline: 10px !important;
    border-radius: 3px;
  }
  .unity {
    height: 250px;
  }
}
@media screen and (max-width: 601px) {
  .CreditDiv{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .creditText{
    text-align: center;
  }
  .selectDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 30px; */

  }
  .selector {
    width: 294px;
  }

}



/* .pdf-container {
  height: 750px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  background-color: #1e1e1e; 
  color: #ffffff; 
}

.pdf-container .rpv-core__viewer {
  background-color: #1e1e1e; 
}

.pdf-container .rpv-core__text-layer {
  color: #ffffff !important; 
} */