.bscUrlText {
  color: orange;
  text-decoration: underline !important;
}
.meta-btn{
  padding: 10px !important;
  width: 100% !important;
  font-size: 12px !important;
}
.filter-with-date-month-year {
  justify-content: right;
  margin-bottom: 15px;
}

.filter-with-date-month-year select {
  background-color: rgb(39, 45, 71);
  color: white;
  max-width: fit-content;
}

.filter-with-date-month-year select:disabled {
  background-color: #606060 !important;
}


/* .printer-top {
  z-index: 1;
  border: 6px solid #666666;
  height: 6px;
  border-bottom: 0;
  border-radius: 6px 6px 0 0;
  background: #333333;
} */

/* .printer-bottom {
  z-index: 0;
  height: 6px;
  border-top: 0;
  border-radius: 0 0 6px 6px;
  background: #333333;
} */

.paper-container {
  position: relative;
  margin-top: 50px;
  overflow: hidden;
  height: 467px;
  display: flex;
  justify-content: center;
}

.paper {
  background: #ffffff;
  font-family: 'Poppins', sans-serif;
  height: 447px;
  position: absolute;
  z-index: 2;
  margin: 0 12px;
  margin-top: -12px;
  /* animation: print 5000ms cubic-bezier(0.68, -0.55, 0.265, 0.9) infinite;
  -moz-animation: print 5000ms cubic-bezier(0.68, -0.55, 0.265, 0.9) infinite; */
}

.main-contents {
  margin: 0 12px;
  padding: 24px;
}

/* // Paper Jagged Edge */
.jagged-edge {
  position: relative;
  height: 20px;
  width: 100%;
  margin-top: -1px;
}

.jagged-edge:after {
  content: "";
  display: block;
  position: absolute;
  /* //bottom: 20px; */
  left: 0;
  right: 0;
  height: 20px;
  background: linear-gradient(45deg,
      transparent 33.333%,
      #ffffff 33.333%,
      #ffffff 66.667%,
      transparent 66.667%),
      linear-gradient(-45deg,
      transparent 33.333%,
      #ffffff 33.333%,
      #ffffff 66.667%,
      transparent 66.667%);
  background-size: 16px 40px;
  background-position: 0 -20px;
}

.success-icon {
  text-align: center;
  font-size: 48px;
  height: 72px;
  background: #359d00;
  border-radius: 50%;
  width: 72px;
  height: 72px;
  margin: 16px auto;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.success-title {
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  color: #666;
  font-weight: bold;
  margin-bottom: 16px;
}

.success-description {
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  line-height: 21px;
  color: #999;
  text-align: center;
  margin-bottom: 24px;
}

.order-details {
  text-align: center;
  color: #333;
  font-weight: bold;

}

.order-number-label {
  font-size: 18px;
  margin-bottom: 8px;
}

.order-number {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  line-height: 48px;
  font-size: 48px;
  padding: 8px 0;
  margin-bottom: 24px;
}

.complement {
  font-size: 18px;
  margin-bottom: 8px;
  color: #32a852;
}

/* 
@keyframes print {
  0% {
      transform: translateY(-90%);
  }

  100% {
      transform: translateY(0%);
  }
}

@-webkit-keyframes print {
  0% {
      -webkit-transform: translateY(-90%);
  }

  100% {
      -webkit-transform: translateY(0%);
  }
}

@-moz-keyframes print {
  0% {
      -moz-transform: translateY(-90%);
  }

  100% {
      -moz-transform: translateY(0%);
  }
}

@-ms-keyframes print {
  0% {
      -ms-transform: translateY(-90%);
  }

  100% {
      -ms-transform: translateY(0%);
  }
} */


.teal{
  background-color: #ffc952 !important;
  color: #444444 !important;
}

.message{
  text-align: left;
}
.price1{
	font-size: 40px;
	font-weight: 200;
	display: block;
	text-align: center;
}
.ui.message p {margin: 5px;}

.ui-middle{
  display: flex;
  justify-content: center;
}




.payment-card {
  width: 100%;

  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.payment-add-icon {
  margin-top: -5px;
}

.card-font-style {
  font-size: 15px;
  font-weight: 600;
  /* line-height: 1.5;
    color: #0d172a; */
}

.add-payment-btn {
  width: 100%;
  background-color: #ff5421;
  border: 0 solid #e2e8f0;
  border-radius: 10px;
  margin-top: 30px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: "Basier circle", -apple-system, system-ui, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1;
  padding: 1rem 1.6rem;
  text-align: center;
  text-decoration: none #0d172a solid;
  text-decoration-thickness: auto;
  transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.25);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.add-payment-btn:hover {
  background-color: #1e293b;
  color: #fff;
}

@media (min-width: 768px) {
  .add-payment-btn {
    font-size: 1.125rem;
    padding: 1rem 2rem;
  }
 
}

@media only screen and (max-width: 450px) {
  .card-fonts {
    font-size: 12px;
  }
  .card-logo {
    width: 20px;
  }
  .meta-btn{
    padding: 5px 2px!important;
    width: 100% !important;
    font-size: 10px !important;
  }
  .w-51{
    width: 50%!important;
  }
}
