.interest-banner-button2:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}

/* ------------------------------------- */
.button-metamask {
  align-items: center;
  background: rgb(194, 116, 0);
  border: 0;
  border-radius: 5px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto,
    "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell,
    "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Lucida Grande", Helvetica, Arial, sans-serif;
  font-size: 12px !important;
  font-weight: 350;
  justify-content: center;
  line-height: 20px;
  max-width: 300px;
  min-height: 40px;
  min-width: 0px;
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  margin: 0;
  /* animation: flash 1s infinite; */

  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}

/* @keyframes flash {
  0% {
    background-color: blue;
  }
  50% {
    background-color: yellow;
  }
  100% {
    background-color: blue;
  }
} */

@keyframes glowing {
  0% {
    background-color: rgb(194, 116, 0);
    -webkit-box-shadow: 0 0 3px rgb(194, 116, 0);
  }

  50% {
    background-color: rgb(194, 116, 0);
    -webkit-box-shadow: 0 0 20px rgb(194, 116, 0);
  }

  100% {
    background-color: rgb(194, 116, 0);
    -webkit-box-shadow: 0 0 3px rgb(194, 116, 0);
  }
}

.button-metamask:hover,
.button-metamask:focus {
  background-color: rgb(163, 98, 0);
  color: #ffffff;
}

.button-metamask:active {
  background-color: rgb(163, 98, 0);
  color: rgb(255, 255, 255, 0.7);
}

.button-metamask:disabled {
  cursor: not-allowed;
  background: rgb(112, 97, 60);
  color: rgba(0, 0, 0, 0.3);
}

/* ----------------------------------------- */

.loaders {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #db3450;
  /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  margin: auto;
}

.swal2-title {
  color: white;
}
.swal-footer {
  text-align: center !important;
}
/* .home-txt-pd {
    padding-top: -50px !important;
   
  } */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.modal_class {
  background-color: #0c2347;
}

.swal-modal {
  background-color: #0c2347;
}

.modal_class_success .swal-icon::before {
  background-color: transparent;
}

.modal_class_success .swal-icon::after {
  background-color: transparent;
}

.swal-icon--success__hide-corners {
  background-color: transparent;
}

.success {
  color: #FF343F;
  margin-top: 1rem;
  padding-bottom: 1.5rem;
}

.extraCare {
  margin-top: 15px;
}

.swal-text {
  text-align: center;
  color: #FF343F;
  font-weight: normal;
}

/* swal */
.modal_class_success {
  background-color: #2D313A !important;
  color: white !important;
}

.swal-text {
  color: #fff !important;
  text-align: center !important;
}

.swal2-html-container p{
  font-size: 16px;
}

.modal_class_success .swal-icon::before {
  background-color: transparent !important;
}

.modal_class_success .swal-icon::after {
  background-color: transparent;
}

.swal-icon--success__hide-corners {
  background-color: transparent;
}

.address {
  font-weight: normal;
  width: 80%;
  color: #8FB9D4 !important;
}

.swal-content {
  padding: 0 20px;
  margin-top: 9px;
  font-size: 11px;
  overflow-wrap: break-word !important;
  color: white !important;
}

.swal-title {
  color: #fff !important;
}

.swal-text {
  color: #fff;
}

.address {
  color: rgb(166, 218, 63) !important;
}

.logo-text{
  font-size: 26px;
  font-weight: 900;
  margin: 0px;
  color: white;
}
.footer-logos{
  margin: 0px 0 20px 0;
}
/* .mobile-menu{
  display: flex !important;
  width: 100%;
  align-items: center;
  justify-content: space-between;
} */
.rs-slider.style1 .slider-content{
  padding: 0px 0!important;
}

.popular-courses .slick-next:before,.popular-courses .slick-prev:before{
 color: #ff5421;
 font-size: 28px;
}
.popular-courses .slick-prev{
  left:-35px;
}
.popular-courses .slick-next{
  right:-27px;
}

.section_heading {
  position: relative;
  margin-top: 12px;
  padding-bottom: 7px;
}
.section_heading:after {
  content: "";
  position: absolute;
  width: 60px;
  height: 2px;
  background: #5e35b1;
  left: 0%;
  bottom: 0;
}

.mcq-part p{
  font-size: 16px;
}