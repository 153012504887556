.dashboardDiv {
  background-color: #1a1c33;
  /* height: 100%; */
  width: 100%;
  overflow-y: auto;
}

.dashboardTxt {
  color: white;
  line-height: 10px;
}

.das-icons {
  font-size: 45px !important;
}

.dashboardTxt p {
  font-size: 0.9rem;
}

.cardDash {
  background-color: #272d47;
  cursor: pointer;
  height: 177px !important;
}

svg {
  color: white;
}

.demoP {
  visibility: hidden;
}

.coinsIcon {
  font-size: 3rem;
  margin-right: 0rem;
  margin-bottom: -0.8rem;
}

.parcentage_text {
  line-height: 12px;
}

.dashboardTxt h6 {
  font-size: 0.9rem;
  text-align: start;
  width: 100%;
}

.cardsBottomText {
  line-height: inherit;
  color: yellow;
  font-size: 13px;
}

@media only screen and (max-width: 994px) {
  .dashboardDiv {
    background-color: #1a1c33;
    height: auto;
    background-size: cover;
  }
}
