.soonPlay{
    color: #ffffff;
    background: #ff5421;
    border-color: #ff5421;
    border-radius: 5px 5px 5px 5px;
    padding: 12px 35px;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase; 
    margin-bottom: 1rem;
}

.faqPart{
    padding-bottom: 125px;
}
.padding-35{
    padding-top: 35px;
}

.header-btn {
    color: #ffffff;
    background: #ff5421;
    border-radius: 5px 5px 5px 5px;
    outline: none;
    padding: 12px 35px;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: "Rubik", sans-serif;
}
.all-course-loader{
    color: red;
}
.cross-btn{
    padding: 2px;
    height: 30px;
    width: 30px;
    border: 0;
    font-size: 12px;
    display: flex;
    align-items: center;
justify-content: center;
background-color: #ff5421;
color:white;
}

.timer-wrapper {
    display: flex;
    justify-content: end;
  }
  
  .timer {
    /* font-family: "Montserrat"; */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .text {
    color: #aaa;
    font-size: 12px;
  }
  
  .value {
    font-size: 20px;
  }
  
  .info {
    max-width: 360px;
    margin: 40px auto 0;
    text-align: center;
    font-size: 16px;
  }
  


.container.mcq-part{
    background-color: #555;
    color: #ddd;
    border-radius: 10px;
    padding: 20px;
    /* font-family: 'Montserrat', sans-serif; */
    max-width: 700px;
}
.container.mcq-part > p{
    font-size: 32px;
}
.question{
    width: 75%;
}
.options, #options{
    position: relative;
    padding-left: 40px;
}

#options label{
    display: block;
    margin-bottom: 15px;
    font-size: 14px;
    cursor: pointer;
}
.options input{
    opacity: 0;
}
.checkmark {
    position: absolute;
    top: -1px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #555;
    border: 1px solid #ddd;
    border-radius: 50%;
}
.options input:checked ~ .checkmark:after {
    display: block;
}
.options .checkmark:after{
    content: "";
	width: 10px;
    height: 10px;
    display: block;
	background: white;
    position: absolute;
    top: 50%;
	left: 50%;
    border-radius: 50%;
    transform: translate(-50%,-50%) scale(0);
    transition: 300ms ease-in-out 0s;
}
.options input[type="radio"]:checked ~ .checkmark{
    background: #21bf73;
    transition: 300ms ease-in-out 0s;
}
.options input[type="radio"]:checked ~ .checkmark:after{
    transform: translate(-50%,-50%) scale(1);
}
.mcq-part .btn-primary{
    background-color: #555;
    padding: 5px 25px;
    color: #ddd;
    border: 1px solid #ddd;
}
.mcq-part .btn-primary:hover{
    background-color: #21bf73;
    border: 1px solid #21bf73;
}
.mcq-part .btn-success{
    padding: 5px 25px;
    background-color: #21bf73;
}
.score-circle{
    width: 150px;
    height: 150px;
    font-size: 32px;
}

@media only screen and (max-width: 600px) {
    .homeMiddleSection{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .padding-35{
        padding-top: 10px;
    }
    .header-btn {
        padding: 10px 30px;
font-size: 12px;
    }
    .timer-wrapper {
        justify-content: center;
      }
      .score-circle{
        width: 120px;
        height: 120px;
    }
}


@media(max-width:576px){
    .question{
        width: 100%;
        word-spacing: 2px;
    } 
    

}


