.edit-nft-card {
  background: #272d47;
  margin: 16px 0 16px 0;
  padding: 1.5rem 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.02);
}

.edit-nft-card-contents {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2.6rem;
}

.video_div_admin {
  width: 100%;
  height: 400px;
  margin-top: 10px;
}

.edit-nft-card-content-one {
  width: 50%;
}

.edit-nft-card-content-two {
  width: 50%;
}

.edit-nft-update-button {
  color: #fff;
  background-color: #705ec8;
  border-color: #705ec8;
  box-shadow: 0 0px 10px -5px rgb(112 94 200 / 50%);
}

.edit-nft-update-button:hover {
  background-color: #6757bc !important;
  border-color: #6757bc !important;
}

@media screen and (max-width: 992px) {
  .edit-nft-card-contents {
    flex-direction: column;
    gap: 0px;
  }

  .edit-nft-card-content-one {
    width: 100%;
  }

  .edit-nft-card-content-two {
    width: 100%;
    margin-top: 25px;
  }

  .edit-nft-card {
    background: #272d47;
    margin: 16px 0 16px 0;
    /* margin-right: 50px; */
    padding: 1.5rem 1.5rem;
    border: 1px solid rgba(255, 255, 255, 0.02);
  }
}
