.imgAdmin {
  width: 60px;
}

.adminCard {
  background-color: #272d47 !important;
  border-radius: 10px;
  overflow: auto;
}

.adminBtn {
  background-color: #6958be;
  color: white;
  border: none;
  border-radius: 7px;
  padding: 10px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  margin-top: 2rem;
}

.nftButtonDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.saveBtn {
  border-radius: 5px;
  background-color: #0d6efd;
  color: #e9e9e9;
  padding: 5px 8px;
  border: none;
  margin-left: 5px;
}

.adminDataTable {
  border: none;
  overflow: scroll;
}

.tableRow {
  padding: 2rem;
}

.AccessBtn {
  border: none;
  background-color: #42c48a;
  padding: 5px 5px;
  border-radius: 5px;
}

.deleteBtn {
  border-radius: 5px;
  background-color: #ef5858;
  color: white;
  padding: 5px 5px;
  border: none;
  margin-left: 5px;
}

.publishBtn {
  border-radius: 5px;
  background-color: #8b50eb;
  color: white;
  padding: 5px 5px;
  border: none;
  margin-left: 5px;
}

.editBtn {
  border-radius: 5px;
  background-color: #42c48a;
  color: white;
  padding: 5px 5px;
  border: none;
  margin-left: 0px;
}

.exclamatoryIcon {
  color: black;
  font-size: 3rem;
}

.delete {
  background-color: #272d47;
}

.deleteDiv p {
  color: white;
}

.Icon {
  text-align: center;
  font-size: 4.5rem;
  color: #ef5858;
}

.copyrightAdmin {
  background-color: #272d47;
  padding: 10px;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

@media only screen and (max-width: 320px) {
  .copyrightAdmin {
    font-size: 13px;
  }
}

.adminBtnDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.activeLegends {
  margin-bottom: -1.2rem;
}

/* .tableNormal{
    overflow: scroll;
} */

/* CERTIFICATES */

.certificate-search-bar-container {
  padding-bottom: 14px;
}

.certificate-search-bar-container input {
  padding-left: 8px;
  width: 100%;
}

@media only screen and (max-width: 994px) {
  .tableNormal {
    overflow: scroll;
  }
}

@media only screen and (max-width: 820px) {
  .editBtn {
    border-radius: 5px;
    background-color: #42c48a;
    padding: 5px 5px;
    border: none;
    margin-left: 0px;
  }

  .tableNormal {
    overflow: scroll;
  }
}

@media only screen and (max-width: 500px) {
  .tableNormal {
    overflow: scroll;
  }

  .title-table-hide {
    display: none;
  }

  .AccessBtn {
    border: none;
    background-color: #42c48a;
    padding: 5px 5px;
    border-radius: 5px;
    margin-left: 0rem;
  }

  .editBtn {
    border-radius: 5px;
    background-color: #42c48a;
    padding: 5px 5px;
    border: none;
    margin-left: 0px;
  }

  .deleteBtn {
    border-radius: 5px;
    background-color: #ef5858;
    padding: 5px 5px;
    border: none;
    border-top: 2px;
  }

  /* .actionDiv {
    height: 4.3rem;
    margin-left: 1rem;
  }

  .action {
    width: 9rem;
  } */

  .adminHidden {
    display: none;
  }

  .publishBtn {
    border-radius: 5px;
    background-color: #8b50eb;
    padding: 5px 8px;
    border: none;
    border-top: 2px;
    margin-top: 5px;
    margin-left: 19px;
  }
}

@media only screen and (max-width: 350px) {
  .adminBtnDiv {
    margin-right: 1rem;
  }

  .publishBtn {
    border-radius: 5px;
    background-color: #8b50eb;
    padding: 5px 8px;
    border: none;
    border-top: 2px;
    margin-top: 5px;
    margin-left: 19px;
  }
}
