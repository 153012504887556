.customH2{
  font-size: 48px;
}

.customH{
  font-size: 28px;
}
.customBr{
  display: none;
  font-weight: bold;
  
}


@media screen and (max-width: 768px) {
  .customH2{
    font-size: 28px;
    text-align: center;
  }
  .customP{
    text-align: center;
    font-weight: 500;
  }
  .btnC{
    text-align: center;
  }
  .customH{
    text-align: center;
  }
  .customBr{
    display: block
  }
}