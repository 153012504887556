input.transparent-input,
textarea.transparent-input {
  background-color: rgba(0, 0, 0, 0) !important;
  border: none !important;
}

.backgroundImg {
  /* background-image: url("https://i.ibb.co/K6zGcH4/final-Certificate.jpg"); */
  background-image: url("https://i.ibb.co/JC2HhZD/certificate.jpg");
  background-size: 680px;
  width: 700px;
  height: 510px;
  background-repeat: no-repeat;
  position: relative;
}

.certificate {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
  flex-direction: column;
}

.abs_wrapper {
  position: absolute;
  width: 100%;
  height: 80%;
  top: 8.5%;
  overflow: hidden;
}

.abs_wrapper .certificateInput {
  position: absolute;
  left: 35%;
  top: 0.5%;
  font-size: 12px;
}

.abs_wrapper .StudentNameInput {
  position: absolute;
  left: 23%;
  top: 40%;
  font-size: 21px;
}

.abs_wrapper .certificateDesInput {
  position: absolute;
  left: 23%;
  top: 54%;
  font-size: 15px;
  color: rgb(248, 241, 241);
}

.abs_wrapper .certificateDesInput textarea {
  color: black;
  width: 470px;
  margin-top: 3px;
}

.abs_wrapper .dateInput {
  position: absolute;
  left: 80%;
  top: 81.4%;
  font-size: 12px;
  width: 100px;
  color: black;
}

@media screen and (max-width: 768px) {
  .backgroundImg {
    background-size: contain;
    width: 390px;
    height: 300px;
  }

  .abs_wrapper {
    width: 95%;
    height: 80%;
    top: 3%;
  }

  .abs_wrapper .certificateInput {
    position: absolute;
    left: 35%;
    top: 7%;
    font-size: 7px;
  }

  .abs_wrapper .StudentNameInput {
    position: absolute;
    left: 25%;
    top: 44%;
    font-size: 15px;
  }

  .abs_wrapper .certificateDesInput {
    position: absolute;
    left: 25%;
    top: 58%;
    font-size: 10px;
    color: black;
  }

  .abs_wrapper .certificateDesInput textarea {
    color: black;
    width: 250px;
  }

  .abs_wrapper .dateInput {
    position: absolute;
    left: 86.5%;
    top: 85.4%;
    font-size: 7px;
    color: black;
  }
}

@media screen and (max-width: 424px) {
  .backgroundImg {
    background-size: contain;
    width: 360px;
    height: 300px;
  }

  .abs_wrapper {
    width: 95%;
    height: 80%;
    top: 5%;
  }

  .abs_wrapper .certificateInput {
    position: absolute;
    left: 34%;
    top: 3.5%;
    font-size: 7px;
  }

  .abs_wrapper .StudentNameInput {
    position: absolute;
    left: 25%;
    top: 38%;
    font-size: 15px;
  }

  .abs_wrapper .certificateDesInput {
    position: absolute;
    left: 25%;
    top: 50%;
    font-size: 9px;
    color: black;
  }

  .abs_wrapper .dateInput {
    position: absolute;
    left: 86%;
    top: 76.9%;
    font-size: 7px;
    color: black;
  }
}

@media screen and (max-width: 374px) {
  .backgroundImg {
    background-size: contain;
    width: 350px;
    height: 300px;
  }

  .abs_wrapper {
    width: 95%;
    height: 80%;
    top: 5%;
  }

  .abs_wrapper .certificateInput {
    position: absolute;
    left: 34%;
    top: 3.2%;
    font-size: 7px;
  }

  .abs_wrapper .StudentNameInput {
    position: absolute;
    left: 24%;
    top: 36%;
    font-size: 15px;
  }

  .abs_wrapper .certificateDesInput {
    position: absolute;
    left: 25%;
    top: 50%;
    font-size: 9px;
    color: black;
  }
  .abs_wrapper .certificateDesInput textarea {
    width: 240px;
    margin-top: 1px;
  }

  .abs_wrapper .dateInput {
    position: absolute;
    left: 86%;
    top: 74%;
    font-size: 7px;
    color: black;
  }
}

@media screen and (max-width: 320px) {
  .backgroundImg {
    background-size: contain;
    width: 310px;
    height: 300px;
  }

  .abs_wrapper {
    width: 95%;
    height: 80%;
  }

  .abs_wrapper .certificateInput {
    position: absolute;
    left: 33%;
    top: 2.3%;
    font-size: 6px;
  }

  .abs_wrapper .StudentNameInput {
    position: absolute;
    left: 24%;
    top: 32%;
    font-size: 13px;
  }

  .abs_wrapper .certificateDesInput {
    position: absolute;
    left: 25%;
    top: 43.7%;
    font-size: 8px;
    color: black;
  }
  .abs_wrapper .certificateDesInput textarea {
    width: 220px;
    margin-top: 0px;
  }

  .abs_wrapper .dateInput {
    position: absolute;
    left: 85.8%;
    top: 64.9%;
    font-size: 6px;
    color: black;
  }
}
