.backgroundImag {
  /* background-image: url("https://i.ibb.co/ScQVBr9/Whats-App-Image-2022-12-15-at-12-30-07-PM.jpg"); */
  /* background-image: url("https://i.ibb.co/JC2HhZD/certificate.jpg"); */
  background-image: url("/public/certificates.jpeg");
  background-size: 645px;
  width: 680px;
  height: 470px;
  background-repeat: no-repeat;
  margin-top: 5px;
  position: relative;
}
.backgroundImag1{
  background-image: url("/public/certificate.png");
  background-size: 645px;
  width: 680px;
  height: 470px;
  background-repeat: no-repeat;
  margin-top: 5px;
  position: relative;
}

.certificateDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4rem;
  /* margin-top: 4rem; */
  flex-direction: column;
}

.mintBntnDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding-bottom: 10px;
  /* flex-direction: column; */

}

.absoluate_wrapper {
  position: absolute;
  width: 100%;
  height: 95%;
  top: 8.5%;
  overflow: hidden;
}

.absoluate_wrapper .certificateNo {
  position: absolute;
  left: 35%;
  top: 1.29%;
  font-size: 12px;
}

.absoluate_wrapper .StudentName {
  position: absolute;
  left: 23%;
  top: 36%;
  font-size: 21px;
}

.absoluate_wrapper .certificateDis {
  position: absolute;
  left: 48.5%;
  top: 42.4%;
  font-size: 13px;
  color: black;

}

.absoluate_wrapper .certificatedateForm {
  position: absolute;
  left: 28%;
  top: 53.9%;
  font-size: 12px;
  color: black;

}
.loading-overlay svg{
  color:green!important;
}
.absoluate_wrapper .certificatedateTo {
  position: absolute;
  left: 60.5%;
  top: 53.9%;
  font-size: 12px;
  color: black;

}

.absoluate_wrapper .dateCer {
  position: absolute;
  left: 77.5%;
  top: 70.7%;
  font-size: 12px;
  color: black;
}

.absoluate_wrapper .SerialCer {
  position: absolute;
  left: 31%;
  top: 82.1%;
  font-size: 12px;
  color: black;
}

.text-size{
  font-size: 21px!important;
}

@media screen and (max-width: 500px) {
  .backgroundImag,.backgroundImag1 {
    background-size: contain;
    width: 100%;
    height: 278px;
  }

  .mintBntnDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding-bottom: 10px;
    padding-top: 10px;
    /* flex-direction: column; */

  }
  .wi-100{
    width: 100%;
  }
  .text-size{
    font-size: 18px!important;
  }
  .absoluate_wrapper {
    width: 95%;
    height: 90%;
    top: 5%;
  }

  .absoluate_wrapper .certificateNo {
    position: absolute;
    left: 39%;
    top: 5.5%;
    font-size: 7px;
  }

  .absoluate_wrapper .StudentName {
    position: absolute;
    left: 25%;
    top: 41%;
    font-size: 15px;
  }

  .absoluate_wrapper .certificateDis {
    position: absolute;
    left: 54%;
    top: 50%;
    font-size: 8px;
    color: black;
  }

  .absoluate_wrapper .certificatedateForm {
    position: absolute;
    left: 31%;
    top: 63.4%;
    font-size: 6.5px;
    color: black;

  }

  .absoluate_wrapper .certificatedateTo {
    position: absolute;
    left: 67.5%;
    top: 63.4%;
    font-size: 6.5px;
    color: black;

  }

  .absoluate_wrapper .dateCer {
    position: absolute;
    left: 86.5%;
    top: 81.4%;
    font-size: 7px;
    color: black;
  }

  .absoluate_wrapper .SerialCer {
    position: absolute;
    left: 34.5%;
    top: 93.9%;
    font-size: 6.6px;
    color: black;
  }
}

@media screen and (max-width: 425px){
  .absoluate_wrapper .certificatedateForm {
    position: absolute;
    left: 30%;
    top: 63.4%;
    font-size: 6.5px;
    color: black;

  }

  .absoluate_wrapper .certificatedateTo {
    position: absolute;
    left: 66%;
    top: 63.4%;
    font-size: 6.5px;
    color: black;

  }

  .absoluate_wrapper .dateCer {
    position: absolute;
    left: 84.5%;
    top: 81.4%;
    font-size: 7px;
    color: black;
  }

  .absoluate_wrapper .SerialCer {
    position: absolute;
    left: 33.5%;
    top: 93.9%;
    font-size: 6.6px;
    color: black;
  }
}


@media screen and (max-width: 415px){
  .absoluate_wrapper .certificatedateForm {
    position: absolute;
    left: 31%;
    top: 62.8%;
    font-size: 6.5px;
    color: black;

  }

  .absoluate_wrapper .certificatedateTo {
    position: absolute;
    left: 67%;
    top: 62.9%;
    font-size: 6.5px;
    color: black;

  }

  .absoluate_wrapper .dateCer {
    position: absolute;
    left: 86.5%;
    top: 80.9%;
    font-size: 7px;
    color: black;
  }

  .absoluate_wrapper .SerialCer {
    position: absolute;
    left: 34.2%;
    top: 93%;
    font-size: 6.6px;
    color: black;
  }
}


@media screen and (max-width: 405px) {
  .backgroundImag,.backgroundImag1 {
    background-size: contain;
    width: 100%;
    height: 263px;
  }

  .absoluate_wrapper {
    width: 95%;
    height: 90%;
    top: 5%;
  }

  .absoluate_wrapper .certificateNo {
    position: absolute;
    left: 39%;
    top: 5.5%;
    font-size: 7px;
  }

  .absoluate_wrapper .StudentName {
    position: absolute;
    left: 25%;
    top: 40%;
    font-size: 15px;
  }

  .absoluate_wrapper .certificateDis {
    position: absolute;
    left: 54%;
    top: 49.5%;
    font-size: 8px;
    color: black;
  }

  .absoluate_wrapper .certificatedateForm {
    position: absolute;
    left: 31%;
    top: 63.5%;
    font-size: 6.5px;
    color: black;

  }

  .absoluate_wrapper .certificatedateTo {
    position: absolute;
    left: 67.5%;
    top: 63.5%;
    font-size: 6.5px;
    color: black;

  }

  .absoluate_wrapper .dateCer {
    position: absolute;
    left: 86%;
    top: 81.1%;
    font-size: 7px;
    color: black;
  }

  .absoluate_wrapper .SerialCer {
    position: absolute;
    left: 34.5%;
    top: 93.7%;
    font-size: 6.6px;
    color: black;
  }
}

@media screen and (max-width: 394px) {
  .backgroundImag,.backgroundImag1 {
    background-size: contain;
    width: 100%;
    height: 263px;
  }

  .absoluate_wrapper {
    width: 95%;
    height: 90%;
    top: 5%;
  }

  .absoluate_wrapper .certificateNo {
    position: absolute;
    left: 39%;
    top: 5.5%;
    font-size: 7px;
  }

  .absoluate_wrapper .StudentName {
    position: absolute;
    left: 25%;
    top: 40%;
    font-size: 15px;
  }

  .absoluate_wrapper .certificateDis {
    position: absolute;
    left: 54%;
    top: 50.2%;
    font-size: 8px;
    color: black;
  }

  .absoluate_wrapper .certificatedateForm {
    position: absolute;
    left: 31%;
    top: 63.5%;
    font-size: 6.5px;
    color: black;

  }

  .absoluate_wrapper .certificatedateTo {
    position: absolute;
    left: 67.5%;
    top: 63.5%;
    font-size: 6.5px;
    color: black;

  }

  .absoluate_wrapper .dateCer {
    position: absolute;
    left: 86%;
    top: 81%;
    font-size: 7px;
    color: black;
  }

  .absoluate_wrapper .SerialCer {
    position: absolute;
    left: 34.5%;
    top: 94%;
    font-size: 6.6px;
    color: black;
  }
}

@media screen and (max-width: 391px) {
  .backgroundImag,.backgroundImag1 {
    background-size: contain;
    width: 100%;
    height: 263px;
  }

  .absoluate_wrapper {
    width: 95%;
    height: 90%;
    top: 5%;
  }

  .absoluate_wrapper .certificateNo {
    position: absolute;
    left: 39%;
    top: 5.5%;
    font-size: 7px;
  }

  .absoluate_wrapper .StudentName {
    position: absolute;
    left: 25%;
    top: 40%;
    font-size: 15px;
  }

  .absoluate_wrapper .certificateDis {
    position: absolute;
    left: 54%;
    top: 49.5%;
    font-size: 8px;
    color: black;
  }

  .absoluate_wrapper .certificatedateForm {
    position: absolute;
    left: 31%;
    top: 62.5%;
    font-size: 6.5px;
    color: black;

  }

  .absoluate_wrapper .certificatedateTo {
    position: absolute;
    left: 67.5%;
    top: 62.5%;
    font-size: 6.5px;
    color: black;

  }

  .absoluate_wrapper .dateCer {
    position: absolute;
    left: 86%;
    top: 80.1%;
    font-size: 7px;
    color: black;
  }

  .absoluate_wrapper .SerialCer {
    position: absolute;
    left: 34.5%;
    top: 92.7%;
    font-size: 6.6px;
    color: black;
  }
}


@media screen and (max-width: 388px) {
  .backgroundImag,.backgroundImag1 {
    background-size: contain;
    width: 100%;
    height: 255px;
  }

  .absoluate_wrapper {
    width: 95%;
    height: 90%;
    top: 5%;
  }

  .absoluate_wrapper .certificateNo {
    position: absolute;
    left: 39%;
    top: 5.2%;
    font-size: 7px;
  }

  .absoluate_wrapper .StudentName {
    position: absolute;
    left: 25%;
    top: 39%;
    font-size: 15px;
  }

  .absoluate_wrapper .certificateDis {
    position: absolute;
    left: 54%;
    top: 48%;
    font-size: 9px;
    color: black;
  }

  .absoluate_wrapper .certificatedateForm {
    position: absolute;
    left: 31%;
    top: 63.5%;
    font-size: 6.5px;
    color: black;

  }

  .absoluate_wrapper .certificatedateTo {
    position: absolute;
    left: 67.5%;
    top: 63.5%;
    font-size: 6.5px;
    color: black;

  }

  .absoluate_wrapper .dateCer {
    position: absolute;
    left: 86%;
    top: 81%;
    font-size: 7px;
    color: black;
  }

  .absoluate_wrapper .SerialCer {
    position: absolute;
    left: 34.5%;
    top: 93.5%;
    font-size: 6.6px;
    color: black;
  }
}
@media screen and (max-width: 375px){

  .absoluate_wrapper .certificatedateForm {
    position: absolute;
    left: 31%;
    top: 62.5%;
    font-size: 4.5px;
    color: black;

  }

  .absoluate_wrapper .certificatedateTo {
    position: absolute;
    left: 67.5%;
    top: 62.3%;
    font-size: 4.5px;
    color: black;

  }

  .absoluate_wrapper .dateCer {
    position: absolute;
    left: 85.8%;
    top: 79.7%;
    font-size: 5px;
    color: black;
  }

  .absoluate_wrapper .SerialCer {
    position: absolute;
    left: 34.5%;
    top: 91.5%;
    font-size: 5.6px;
    color: black;
  }
}

@media screen and (max-width: 361px) {
  .backgroundImag,.backgroundImag1 {
    background-size: contain;
    width: 100%;
    height: 250px;
  }

  .absoluate_wrapper {
    width: 95%;
    height: 90%;
    top: 5%;
  }

  .absoluate_wrapper .certificateNo {
    position: absolute;
    left: 39%;
    top: 4.9%;
    font-size: 7px;
  }

  .absoluate_wrapper .StudentName {
    position: absolute;
    left: 25%;
    top: 39%;
    font-size: 15px;
  }

  .absoluate_wrapper .certificateDis {
    position: absolute;
    left: 54%;
    top: 47.5%;
    font-size: 8px;
    color: black;
  }

  .absoluate_wrapper .certificatedateForm {
    position: absolute;
    left: 31%;
    top: 60%;
    font-size: 6.5px;
    color: black;

  }

  .absoluate_wrapper .certificatedateTo {
    position: absolute;
    left: 67.5%;
    top: 60%;
    font-size: 6.5px;
    color: black;

  }

  .absoluate_wrapper .dateCer {
    position: absolute;
    left: 86.5%;
    top: 77%;
    font-size: 7px;
    color: black;
  }

  .absoluate_wrapper .SerialCer {
    position: absolute;
    left: 34.5%;
    top: 89.4%;
    font-size: 6.6px;
    color: black;
  }
}
@media screen and (max-width: 320px){
  .absoluate_wrapper .certificateNo {
    position: absolute;
    left: 39%;
    top: 3.8%;
    font-size: 6px;
  }

  .absoluate_wrapper .StudentName {
    position: absolute;
    left: 25%;
    top: 35%;
    font-size: 9px;
  }

  .absoluate_wrapper .certificateDis {
    position: absolute;
    left: 54%;
    top: 41.5%;
    font-size: 6px;
    color: black;
  }
  .absoluate_wrapper .certificatedateForm {
    position: absolute;
    left: 31%;
    top: 52.5%;
    font-size: 4.5px;
    color: black;

  }

  .absoluate_wrapper .certificatedateTo {
    position: absolute;
    left: 67.5%;
    top: 52.8%;
    font-size: 4.5px;
    color: black;

  }

  .absoluate_wrapper .dateCer {
    position: absolute;
    left: 85.8%;
    top: 67.9%;
    font-size: 5px;
    color: black;
  }

  .absoluate_wrapper .SerialCer {
    position: absolute;
    left: 34.5%;
    top: 78%;
    font-size: 5.6px;
    color: black;
  }

}
@media screen and (max-width: 300px) {
  .backgroundImag,.backgroundImag1 {
    background-size: contain;
    width: 100%;
    height: 200px;
  }

  .absoluate_wrapper {
    width: 95%;
    height: 90%;
    top: 5%;
  }

  .absoluate_wrapper .certificateNo {
    position: absolute;
    left: 39%;
    top: 4.2%;
    font-size: 6px;
  }

  .absoluate_wrapper .StudentName {
    position: absolute;
    left: 25%;
    top: 36%;
    font-size: 9px;
  }

  .absoluate_wrapper .certificateDis {
    position: absolute;
    left: 54%;
    top: 45.5%;
    font-size: 6px;
    color: black;
  }

  .absoluate_wrapper .certificatedateForm {
    position: absolute;
    left: 31%;
    top: 57%;
    font-size: 4.5px;
    color: black;

  }

  .absoluate_wrapper .certificatedateTo {
    position: absolute;
    left: 67.5%;
    top: 57%;
    font-size: 4.5px;
    color: black;

  }

  .absoluate_wrapper .dateCer {
    position: absolute;
    left: 85.8%;
    top: 74%;
    font-size: 5px;
    color: black;
  }

  .absoluate_wrapper .SerialCer {
    position: absolute;
    left: 34.5%;
    top: 84.3%;
    font-size: 5.6px;
    color: black;
  }
}