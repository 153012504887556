.forRespoMarginReduce {
  margin-left: 70px !important;
  margin-right: 100px !important;
}

.char-limit-position {
  position: absolute;
  font-size: 12px;
  right: 4px;
  bottom: 13px;
}

.template-icon-button {
  background-color: rebeccapurple;
  border: none;
  border-radius: 4px;
}

.template-icon-button-container {
  background-color: rebeccapurple;
  border: none;
  border-radius: 4px;
  display: block;
  margin-left: autoo;
  margin-left: auto;
  margin-right: 7px;
  max-width: fit-content;
}

.selected-image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 13px;
  margin-bottom: 13px;
  padding-left: 136px;
}

.each-selected-image {
  /* width: inherit;
    height: inherit;
    border-radius: 6px; */
  width: 100%;
  height: auto;
  object-fit: contain;
}

.each-selected-image-container {
  position: relative;

  display: flex;
  align-items: center;
  width: 91px;
  height: 91px;
}

.selected-image-remove-button {
  color: white;
  background-color: #404040;
  border-radius: 100px;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.course-name-input-container {
  position: relative;
}

.edit-char-limit-position {
  position: absolute;
  font-size: 12px;
  right: 4px;
  bottom: 13px;
}

.imageDivNft {
  /* width: 200px; */
  height: 200px;
  border: 1px solid #8F5F5F;
  overflow: hidden;
}

.extraCare {
  margin-top: 15px;
}

/* CSS for addCourse */
.selected-video-container {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.each-selected-video-for-priview {
  width: 14%;
}

.each-selected-video-container {
  width: 100%;
  height: auto;
  position: relative;
}
.each-selected-video-container video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Customizing the position of template popover */
.template-popover .popover-arrow {
  top: -88px !important;
}

.template-popover {
  inset: 90px 0px auto auto !important;
}

.template-header h5 {
  margin-bottom: 3px;
}

.template-header p {
  margin-bottom: 10px;
}

@media screen and (max-width: 600px) {
  .each-selected-video-for-priview {
    width: 100%;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @media only screen and (max-width: 900px) and (min-width: 700px) {
    .forRespoMarginReduce {
      margin-left: 30px !important;
      margin-right: 50px !important;
    }
  }

  @media only screen and (max-width: 767px) {
    .char-limit-position {
      font-size: 10px;
    }

    .edit-char-limit-position {
      font-size: 10px;
    }

    .forRespoMarginReduce {
      margin-left: 30px !important;
      margin-right: 50px !important;
    }
  }

  @media only screen and (max-width: 650px) {
    .extraCare {
      margin-top: 15px;
    }

    .forRespoMarginReduce {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
  }

  @media only screen and (max-width: 480px) {
    .modal-btn {
      width: 88px !important;
      font-size: 9px !important;
    }
  }
}









.addAdminImg {
  width: 100px;
  border-radius: 50%;
}

.dailogueAdmin {
  background-color: #272D47;
}

.adminBtnAdd {
  background-color: #6958BE;
  color: white;
  border: none;
  border-radius: 7px;
  padding: 10px;
  margin: 1.5rem auto;
  width: 25%;
}

.adminBtnAdd11 {
  background-color: rgb(241, 89, 89);
  color: white;
  border: none;
  border-radius: 7px;
  padding: 10px;
  margin: 1.5rem auto;
  width: 25%;
}

.addAdminImgBtn {
  background-color: #6958BE;
  color: white;
  border: none;
  border-radius: 7px;
  margin-bottom: 0.5rem;
}

.addAdminDiv p {
  color: rgb(177, 185, 195);
  margin-bottom: -0.2rem;
  margin-top: 1rem;
}

.linkTitle {
  color: rgb(177, 185, 195);
}

.addAdminDiv input,
textarea {
  background-color: transparent;
  padding: 6px;
  border: 1px solid rgb(177, 185, 195);
  color: rgb(177, 185, 195);
  border-radius: 5px;
  width: 100%;
}