/* .headerButtonW{
    border: 1px solid #FF5421;
    padding: 8px 12px;
    width: 210px;
} */
.loginbuttonmobile {
  /* margin-bottom: 1rem; */
  /* margin-left: 22px; */
  display: flex;
  gap: 12px;
  /* align-items: center; */
  /* justify-content: center; */
}

.formobileonly{
  margin-left: 40px;

}
.loginbuttonPc {
  margin-left: 1.5rem;
}

.btncommon {
  border-radius: 8px;
}

.search-input {
  width: 100%;
}

.search-input-icon {
  position: absolute;
  /* font-size: 8px !important; */
  color: white;
  right: 20px;
  bottom: 1px;
  cursor: pointer;
}

.headerButtonW {
  align-items: center;
  /* background: linear-gradient(to right, var(--secondary-color) 0%, var(--secondary-color-2) 100%); */
  background: #095e68;
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto,
    "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell,
    "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Lucida Grande", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  max-width: 470px;
  min-height: 40px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  margin: 0.8rem 0;
}

.headerButtonWGoogle {
  align-items: center;
  /* background: linear-gradient(to right, var(--secondary-color) 0%, var(--secondary-color-2) 100%); */
  background: #095e68;
  border: 0;
  border-radius: 50px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto,
    "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell,
    "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Lucida Grande", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  max-width: 480px;
  min-height: 40px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 19px;
  padding-right: 19px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  margin: 0.8rem 0;
}

.headerButtonW:hover,
.headerButtonW:focus {
  background-color: #004c55;
  /* background-color: linear-gradient(to right, var(--secondary-color) 0%, var(--secondary-color-2) 100%); */
  color: #ffffff;
}

.headerButtonW:active {
  background-color: #004c55;
  color: rgb(255, 255, 255, 0.7);
}

.headerButtonW:disabled {
  cursor: not-allowed;
  background: linear-gradient(
    to right,
    var(--secondary-color) 0%,
    var(--secondary-color-2) 100%
  );
  color: rgba(0, 0, 0, 0.3);
}

.bannerButtonW {
  align-items: center;
  /* background: linear-gradient(to right, var(--secondary-color) 0%, var(--secondary-color-2) 100%); */
  background: rgb(194, 116, 0);
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto,
    "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell,
    "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Lucida Grande", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  width: 223px;
  min-height: 40px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  margin: 0;
  /* animation: flash 1s infinite; */

  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}

.wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons {
  min-width: 250px;
  min-height: 60px;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  font-weight: 600;
  color: white;
  background: #ff5421;
background: linear-gradient(90deg, #ff5421 0%, #e53600fc 100%);
  border: none;
  border-radius: 1000px;
  box-shadow: 12px 12px 24px #ff552183;
  transition: all 0.3s ease-in-out 0s;  
  cursor: pointer;
  outline: none;
  position: relative;
  padding: 8px;
  }

.buttons::before {
content: '';
  border-radius: 1000px;
  min-width: calc(300px + 12px);
  min-height: calc(60px + 12px);
  border: 6px solid #ff5421;
  box-shadow: 0 0 60px #ff552183;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all .3s ease-in-out 0s;
}

.buttons:hover, .buttons:focus {
  color: #313133;
  transform: translateY(-4px);
}

/* .buttons:hover::before, .buttons:focus::before {
  opacity: 1;
} */

.buttons::after {
  content: '';
  width: 30px; height: 30px;
  border-radius: 100%;
  border: 6px solid #ff552183;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
}

.buttons:hover::after, .buttons:focus::after {
  animation: none;
  display: none;
}

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 300px;
    height: 300px;
    opacity: 0;
  }
}

@keyframes glowing {
  0% {
    background-color: rgb(194, 116, 0);
    -webkit-box-shadow: 0 0 3px rgb(194, 116, 0);
  }

  50% {
    background-color: rgb(194, 116, 0);
    -webkit-box-shadow: 0 0 20px rgb(194, 116, 0);
  }

  100% {
    background-color: rgb(194, 116, 0);
    -webkit-box-shadow: 0 0 3px rgb(194, 116, 0);
  }
}

.bannerButtonW:hover,
.bannerButtonW:focus {
  background-color: rgb(163, 98, 0);
  color: #ffffff;
}

.bannerButtonW:active {
  background-color: rgb(163, 98, 0);
  color: rgb(255, 255, 255, 0.7);
}

.bannerButtonW:disabled {
  cursor: not-allowed;
  background: rgb(112, 97, 60);
  color: rgba(0, 0, 0, 0.3);
}

.nav-bar-info-button {
  margin-right: 0px !important;
}

.nav-bar-info-button button {
  background: none;
  border: none;
}
@media only screen and (max-width: 600px) {
  .nav-bar-info-button button {
    background: none;
    border: none;
    display: block;
    margin: auto;
    margin-top: -8px;
  }
  .loginbuttonmobile {
    
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
  }
  .logo-text{
    font-size: 20px!important;
  }
  .mobile-menu{
  /* display: flex !important; */
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
}