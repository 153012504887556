.question-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 100px; */
  font-family: Arial, Helvetica, sans-serif;
  background-color: #afdde3;
  padding-block: 18px;
}

/* h2 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 40px;
  } */

.mcq-button-container {
  display: flex;
  flex-direction: row;
}

.answer-button {
  padding: 20px 40px;
  margin: 0 20px;
  border: none;
  border-radius: 40px;
  background-color: #a9a9a9;
  color: white;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.answer-button:hover {
  background-color: #696969;
}

.mcq-next-button {
  padding: 20px 40px;
  border: none;
  border-radius: 40px;
  background-color: #5e5e5e;
  color: white;
  font-size: 24px;
  font-weight: bold;
}

.pdf-container {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.overview-pdf {
  border: none; /* Remove default iframe border */
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: block;
}

@media only screen and (max-width: 600px) {
  .question{
    font-size: 18px;
  }
}

