.action-type-selector {
  flex: unset !important;
  display: block !important;
  width: max-content !important;
  margin-bottom: 10px !important;
  margin-left: auto !important;

  /* This sets the color of the arrow */
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  /* This sets the background of the arrow */
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='white' d='M10 15L5 5h10l-5 10z'/></svg>") !important;
  background-repeat: no-repeat !important;
  background-position: right center !important;
  background-size: 20px 10px !important;
}
